.auth-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.auth-heading {
  font-size: 100px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
}
@media(max-width: 767px) {
  .auth-heading {
    font-size: 60px;
  }
}
@media(max-width: 479px) {
  .auth-heading {
    font-size: 36px;
  }
}
