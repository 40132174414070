.check-check {
  width: 32px;
  height: 32px;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-items: center;
  border-color: #ffffff;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
}
.check-icon {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
