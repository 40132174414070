.showcase-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.showcase-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.showcase-navlink {
  display: contents;
}
.showcase-branding {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.showcase-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.showcase-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: 3d9363;
}
.showcase-hamburger:hover {
  opacity: .5;
}
.showcase-icon {
  width: 60px;
  object-fit: cover;
}
.showcase-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.showcase-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.showcase-navlink01 {
  display: contents;
}
.showcase-image {
  height: 2rem;
  text-decoration: none;
}
.showcase-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.showcase-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.showcase-nav1 {
  flex: 0 0 auto;
  width: 139px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-navlink02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.showcase-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.showcase-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.showcase-navlink03 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.showcase-navlink04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.showcase-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 183px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.showcase-login {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.showcase-register {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.showcase-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.showcase-heading {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.showcase-text {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.showcase-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.showcase-component {
  text-decoration: none;
}
.showcase-navlink05 {
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--dl-color-gray-black);
}
.showcase-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.showcase-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.showcase-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.showcase-branding1 {
  gap: 0;
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-navlink06 {
  display: contents;
}
.showcase-image1 {
  width: auto;
  object-fit: cover;
  text-decoration: none;
}
.showcase-text1 {
  color: #ffffff;
  line-height: 24px;
}
.showcase-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.showcase-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 173px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-header {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.showcase-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-navlink07 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink07:hover {
  opacity: .5;
}
.showcase-navlink08 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink08:hover {
  opacity: .5;
}
.showcase-link3 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-link3:hover {
  opacity: .5;
}
.showcase-navlink09 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink09:hover {
  opacity: .5;
}
.showcase-text2:hover {
  opacity: .5;
}
.showcase-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 144px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-header1 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.showcase-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-link4 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-link4:hover {
  opacity: .5;
}
.showcase-link5 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-link5:hover {
  opacity: .5;
}
.showcase-navlink10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink10:hover {
  opacity: .5;
}
.showcase-navlink11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink11:hover {
  opacity: .5;
}
.showcase-navlink12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink12:hover {
  opacity: .5;
}
.showcase-column2 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 147px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-header2 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.showcase-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.showcase-navlink13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink13:hover {
  opacity: .5;
}
.showcase-link6 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-link6:hover {
  opacity: .5;
}
.showcase-navlink14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink14:hover {
  opacity: .5;
}
.showcase-navlink15 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-navlink15:hover {
  opacity: .5;
}
.showcase-link7 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.showcase-link7:hover {
  opacity: .5;
}
.showcase-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.showcase-text4 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
@media(max-width: 991px) {
  .showcase-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .showcase-nav1 {
    width: 149px;
  }
  .showcase-navlink04 {
    text-decoration: none;
  }
  .showcase-container2 {
    gap: var(--dl-space-space-unit);
    width: 180px;
    flex-wrap: wrap;
  }
  .showcase-login {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-register {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-text {
    text-align: center;
  }
  .showcase-container3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .showcase-content {
    border-top-width: 0px;
  }
  .showcase-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .showcase-branding1 {
    width: 100%;
  }
  .showcase-image1 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .showcase-burger-menu {
    display: flex;
  }
  .showcase-nav1 {
    width: 131px;
  }
  .showcase-navlink03 {
    text-decoration: none;
  }
  .showcase-navlink04 {
    text-decoration: none;
  }
  .showcase-container2 {
    gap: var(--dl-space-space-unit);
    width: 175px;
    flex-wrap: wrap;
  }
  .showcase-login {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-register {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .showcase-heading {
    font-size: 40px;
  }
  .showcase-text {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .showcase-container3 {
    grid-template-columns: 1fr 1fr;
  }
  .showcase-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .showcase-content {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .showcase-image1 {
    width: auto;
  }
  .showcase-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .showcase-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .showcase-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .showcase-icon {
    width: 30px;
  }
  .showcase-mobile-menu {
    padding: 16px;
  }
  .showcase-nav {
    align-self: flex-end;
    flex-direction: column;
  }
  .showcase-nav1 {
    width: 150px;
  }
  .showcase-container2 {
    gap: var(--dl-space-space-unit);
    width: 182px;
    flex-wrap: wrap;
    align-self: flex-end;
    margin-top: 0px;
    justify-content: flex-start;
  }
  .showcase-login {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-register {
    height: var(--dl-size-size-xsmall);
  }
  .showcase-gallery {
    padding: var(--dl-space-space-unit);
  }
  .showcase-heading {
    color: rgb(255, 255, 255);
  }
  .showcase-container3 {
    grid-template-columns: 1fr;
  }
  .showcase-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .showcase-link4 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .showcase-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
}
