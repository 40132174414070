.vlu-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.vlu-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.vlu-navlink {
  display: contents;
}
.vlu-branding {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.vlu-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vlu-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: 3d9363;
}
.vlu-hamburger:hover {
  opacity: .5;
}
.vlu-icon {
  width: 60px;
  object-fit: cover;
}
.vlu-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.vlu-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.vlu-navlink01 {
  display: contents;
}
.vlu-image {
  height: 2rem;
  text-decoration: none;
}
.vlu-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vlu-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.vlu-nav1 {
  flex: 0 0 auto;
  width: 139px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-navlink02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.vlu-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.vlu-link01 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.vlu-link02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.vlu-navlink03 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.vlu-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 183px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.vlu-login {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.vlu-register {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.vlu-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.vlu-heading {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.vlu-text {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.vlu-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.vlu-component {
  text-decoration: none;
}
.vlu-component01 {
  text-decoration: none;
}
.vlu-component02 {
  text-decoration: none;
}
.vlu-component03 {
  text-decoration: none;
}
.vlu-component04 {
  text-decoration: none;
}
.vlu-component05 {
  text-decoration: none;
}
.vlu-component06 {
  text-decoration: none;
}
.vlu-component07 {
  text-decoration: none;
}
.vlu-component08 {
  text-decoration: none;
}
.vlu-component09 {
  text-decoration: none;
}
.vlu-component10 {
  text-decoration: none;
}
.vlu-component11 {
  text-decoration: none;
}
.vlu-component12 {
  text-decoration: none;
}
.vlu-component13 {
  text-decoration: none;
}
.vlu-component14 {
  text-decoration: none;
}
.vlu-component15 {
  text-decoration: none;
}
.vlu-component16 {
  text-decoration: none;
}
.vlu-component17 {
  text-decoration: none;
}
.vlu-component18 {
  text-decoration: none;
}
.vlu-component19 {
  text-decoration: none;
}
.vlu-component20 {
  text-decoration: none;
}
.vlu-component21 {
  text-decoration: none;
}
.vlu-component22 {
  text-decoration: none;
}
.vlu-component23 {
  text-decoration: none;
}
.vlu-component24 {
  text-decoration: none;
}
.vlu-component25 {
  text-decoration: none;
}
.vlu-component26 {
  text-decoration: none;
}
.vlu-component27 {
  text-decoration: none;
}
.vlu-component28 {
  text-decoration: none;
}
.vlu-component29 {
  text-decoration: none;
}
.vlu-component30 {
  text-decoration: none;
}
.vlu-navlink04 {
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--dl-color-gray-black);
}
.vlu-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.vlu-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.vlu-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.vlu-branding1 {
  gap: 0;
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-navlink05 {
  display: contents;
}
.vlu-image1 {
  width: auto;
  object-fit: cover;
  text-decoration: none;
}
.vlu-text1 {
  color: #ffffff;
  line-height: 24px;
}
.vlu-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.vlu-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 173px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-header {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.vlu-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-navlink06 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink06:hover {
  opacity: .5;
}
.vlu-navlink07 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink07:hover {
  opacity: .5;
}
.vlu-link34 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-link34:hover {
  opacity: .5;
}
.vlu-text2 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-text2:hover {
  opacity: .5;
}
.vlu-text3:hover {
  opacity: .5;
}
.vlu-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 144px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-header1 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.vlu-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-link35 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-link35:hover {
  opacity: .5;
}
.vlu-link36 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-link36:hover {
  opacity: .5;
}
.vlu-navlink08 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink08:hover {
  opacity: .5;
}
.vlu-navlink09 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink09:hover {
  opacity: .5;
}
.vlu-navlink10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink10:hover {
  opacity: .5;
}
.vlu-column2 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 147px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-header2 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.vlu-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.vlu-navlink11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink11:hover {
  opacity: .5;
}
.vlu-link37 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-link37:hover {
  opacity: .5;
}
.vlu-navlink12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink12:hover {
  opacity: .5;
}
.vlu-navlink13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-navlink13:hover {
  opacity: .5;
}
.vlu-link38 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.vlu-link38:hover {
  opacity: .5;
}
.vlu-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.vlu-text5 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
@media(max-width: 991px) {
  .vlu-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .vlu-nav1 {
    width: 149px;
  }
  .vlu-navlink03 {
    text-decoration: none;
  }
  .vlu-container2 {
    gap: var(--dl-space-space-unit);
    width: 180px;
    flex-wrap: wrap;
  }
  .vlu-login {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-register {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-text {
    text-align: center;
  }
  .vlu-container3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .vlu-content {
    border-top-width: 0px;
  }
  .vlu-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .vlu-branding1 {
    width: 100%;
  }
  .vlu-image1 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .vlu-burger-menu {
    display: flex;
  }
  .vlu-nav1 {
    width: 131px;
  }
  .vlu-navlink03 {
    text-decoration: none;
  }
  .vlu-container2 {
    gap: var(--dl-space-space-unit);
    width: 175px;
    flex-wrap: wrap;
  }
  .vlu-login {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-register {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .vlu-heading {
    font-size: 40px;
  }
  .vlu-text {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .vlu-container3 {
    grid-template-columns: 1fr 1fr;
  }
  .vlu-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .vlu-content {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .vlu-image1 {
    width: auto;
  }
  .vlu-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .vlu-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .vlu-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .vlu-icon {
    width: 30px;
  }
  .vlu-mobile-menu {
    padding: 16px;
  }
  .vlu-nav {
    align-self: flex-end;
    flex-direction: column;
  }
  .vlu-nav1 {
    width: 150px;
  }
  .vlu-container2 {
    gap: var(--dl-space-space-unit);
    width: 182px;
    flex-wrap: wrap;
    align-self: flex-end;
    margin-top: 0px;
    justify-content: flex-start;
  }
  .vlu-login {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-register {
    height: var(--dl-size-size-xsmall);
  }
  .vlu-gallery {
    padding: var(--dl-space-space-unit);
  }
  .vlu-heading {
    color: rgb(255, 255, 255);
  }
  .vlu-container3 {
    grid-template-columns: 1fr;
  }
  .vlu-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .vlu-link35 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .vlu-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
}
