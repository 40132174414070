.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.home-cover-video {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-video {
  top: 0px;
  left: 0px;
  width: 100%;
  filter: opacity(0.6);
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-navlink {
  display: contents;
}
.home-branding {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.home-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: 3d9363;
}
.home-hamburger:hover {
  opacity: .5;
}
.home-icon {
  width: 60px;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-navlink01 {
  display: contents;
}
.home-image {
  height: 2rem;
  text-decoration: none;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon01 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav1 {
  flex: 0 0 auto;
  width: 139px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link01 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink03 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 183px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-login {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-register {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-cover {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  font-size: 100px;
  max-width: 790px;
  font-style: normal;
  font-weight: 500;
}
.home-text03 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-button {
  background-color: #3d9363;
}
.home-link02 {
  text-decoration: none;
}
.home-key-notes {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 242px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
}
.home-caption01 {
  color: var(--dl-color-gray-white);
  font-size: 40px;
  max-width: 800px;
  align-self: center;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 52px;
  margin-right: 0px;
}
.home-caption02 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 800px;
  align-self: center;
  text-align: center;
  line-height: 36px;
}
.home-statistics {
  width: 100%;
  height: var(--dl-size-size-xlarge);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.home-content {
  width: 100%;
  height: var(--dl-size-size-large);
  display: flex;
  align-items: flex-start;
  padding-top: 0px;
  border-color: #313133;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-bottom-width: 1px;
}
.home-stat {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-right-width: 1px;
}
.home-header01 {
  color: rgb(255, 255, 255);
  font-size: 42px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption03 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat1 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-right-width: 1px;
}
.home-header02 {
  color: rgb(255, 255, 255);
  font-size: 42px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption04 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-stat2 {
  gap: var(--dl-space-space-halfunit);
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
}
.home-header03 {
  color: rgb(255, 255, 255);
  font-size: 42px;
  font-style: italic;
  text-align: center;
  font-family: Playfair Display;
  font-weight: 500;
  line-height: 73px;
}
.home-caption05 {
  color: rgb(124, 124, 128);
  font-size: 16px;
  line-height: 24px;
}
.home-slider {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
}
.home-header04 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading01 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  max-width: 900px;
  font-style: normal;
  font-weight: 400;
}
.home-text08 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-selector {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-caption06 {
  color: rgb(124, 124, 128);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-slide-titles {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-slides {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-slider1 {
  gap: var(--dl-space-space-fiveunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-container {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-slider-track-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-slider-track {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-image01 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content01 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header05 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading02 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption07 {
  color: #DADAE7;
  max-width: 600px;
  line-height: 24px;
}
.home-image02 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content02 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header06 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading03 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption08 {
  color: #dadae7;
  max-width: 600px;
  line-height: 24px;
}
.home-image03 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content03 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header07 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading04 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption09 {
  color: #DADAE7;
  max-width: 600px;
  line-height: 24px;
}
.home-image04 {
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content04 {
  height: 400px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-header08 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-heading05 {
  color: rgb(255, 255, 255);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
}
.home-caption10 {
  color: #DADAE7;
  max-width: 600px;
  line-height: 24px;
}
.home-controls {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-previous {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.home-icon03 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-next {
  display: flex;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
}
.home-icon05 {
  fill: #ffffff;
  width: 24px;
  height: 24px;
}
.home-get-started {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-header-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading06 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-caption11 {
  color: #e5e5e5;
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.home-button1 {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-link03 {
  text-decoration: none;
}
.home-image05 {
  top: 0px;
  right: 0px;
  width: 720px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.home-objectives {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  border-top-width: 1px;
}
.home-objectives-list {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: 0px;
  flex-direction: row;
  justify-content: flex-start;
}
.home-text13 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text14 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-text15 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text16 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-text17 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.home-text18 {
  color: rgb(124, 124, 128);
  line-height: 24px;
}
.home-growth {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-image06 {
  width: 640px;
  z-index: 100;
  margin-top: 0px;
  object-fit: cover;
}
.home-content06 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading07 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text20 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-caption12 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-caption13 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
  border-color: #313133;
  padding-bottom: var(--dl-space-space-threeunits);
  border-bottom-width: 1px;
}
.home-testimonial {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-content07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text22 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-text23 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-information {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-author {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-icon07 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-name {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-from {
  width: 125px;
  object-fit: cover;
}
.home-experience {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-content08 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-header-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header13 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading08 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text25 {
  font-family: Playfair Display;
}
.home-caption14 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  line-height: 36px;
}
.home-checkmarks {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-check {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3d9363;
}
.home-icon08 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text26 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-check1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark1 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3d9363;
}
.home-icon10 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text27 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-check2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark2 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3d9363;
}
.home-icon12 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text28 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-check3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-mark3 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
  background-color: #3d9363;
}
.home-icon14 {
  fill: #ffffff;
  width: 12px;
  height: 12px;
}
.home-text29 {
  color: rgb(255, 255, 255);
  line-height: 24px;
}
.home-testimonial1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-content09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text30 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 5%;
  text-transform: uppercase;
}
.home-text31 {
  color: rgb(255, 255, 255);
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 5%;
}
.home-information1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-author1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: #7C7C80;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  justify-content: center;
  border-right-width: 1px;
}
.home-icon16 {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-name1 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 27px;
}
.home-from1 {
  width: 100px;
  object-fit: cover;
}
.home-image07 {
  width: 640px;
  z-index: 100;
  object-fit: cover;
}
.home-button3 {
  display: flex;
  align-self: center;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.home-navlink05 {
  text-decoration: none;
}
.home-gallery {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
}
.home-heading09 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text32 {
  color: var(--dl-color-gray-700);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  padding-left: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-threeunits);
}
.home-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.home-component {
  text-decoration: none;
}
.home-component01 {
  text-decoration: none;
}
.home-component02 {
  text-decoration: none;
}
.home-component03 {
  text-decoration: none;
}
.home-component04 {
  text-decoration: none;
}
.home-link09 {
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--dl-color-gray-black);
}
.home-pricing {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-caption15 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 24px;
  max-width: 560px;
  text-align: center;
  line-height: 36px;
}
.home-table {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-row {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.home-headers {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text35 {
  color: rgba(255, 255, 255, 0);
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-plans {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row01 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
}
.home-text36 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;
}
.home-text37 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;
}
.home-text38 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 36px;
  padding-top: 0px;
  padding-bottom: 0px;
  text-transform: uppercase;
}
.home-row02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers01 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text39 {
  color: rgba(255, 255, 255, 0);
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-top: var(--dl-space-space-halfunit);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-halfunit);
  border-bottom-width: 1px;
}
.home-plans01 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row03 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text40 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: capitalize;
}
.home-text41 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: capitalize;
}
.home-text42 {
  color: rgb(124, 124, 128);
  width: 100%;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-transform: uppercase;
}
.home-row04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers02 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text43 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row05 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers03 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text44 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans03 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row07 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers04 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text45 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans04 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row09 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text46 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text47 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text48 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers05 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text49 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans05 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row11 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-text50 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text51 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-text52 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
}
.home-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers06 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text53 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans06 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row13 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row14 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers07 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text54 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans07 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row15 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers08 {
  flex: 0.6;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-text55 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #313133;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.home-plans08 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row17 {
  width: 100%;
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-headers09 {
  flex: 0.6;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-link10 {
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--dl-color-gray-black);
}
.home-plans09 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row19 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  border-color: #313133;
  padding-right: 180px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
}
.home-link11 {
  text-align: center;
  text-decoration: none;
}
.home-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 160px;
}
.home-header15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading11 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text57 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-content10 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-column {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  height: 264px;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content11 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  height: 265px;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header17 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content12 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  height: 258px;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header18 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content13 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-column1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  height: 264px;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header19 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content14 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  height: 265px;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header20 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content15 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border-color: #313133;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  border-right-width: 1px;
  border-bottom-width: 1px;
}
.home-header21 {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content16 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-link12 {
  font-size: 18px;
  text-decoration: underline;
  background-color: var(--dl-color-gray-black);
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-content17 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.home-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-branding1 {
  gap: 0;
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink06 {
  display: contents;
}
.home-image08 {
  width: auto;
  object-fit: cover;
  text-decoration: none;
}
.home-text59 {
  color: #ffffff;
  line-height: 24px;
}
.home-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-column2 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 173px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header22 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink07 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink07:hover {
  opacity: .5;
}
.home-navlink08 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink08:hover {
  opacity: .5;
}
.home-link13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link13:hover {
  opacity: .5;
}
.home-navlink09 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink09:hover {
  opacity: .5;
}
.home-text60:hover {
  opacity: .5;
}
.home-column3 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 144px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header23 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link14:hover {
  opacity: .5;
}
.home-link15 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link15:hover {
  opacity: .5;
}
.home-navlink10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink10:hover {
  opacity: .5;
}
.home-navlink11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink11:hover {
  opacity: .5;
}
.home-navlink12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink12:hover {
  opacity: .5;
}
.home-column4 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 147px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header24 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.home-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navlink13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink13:hover {
  opacity: .5;
}
.home-link16 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link16:hover {
  opacity: .5;
}
.home-navlink14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink14:hover {
  opacity: .5;
}
.home-navlink15 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-navlink15:hover {
  opacity: .5;
}
.home-link17 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.home-link17:hover {
  opacity: .5;
}
.home-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text62 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
.home-button5 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 0px;
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
}
.home-image09 {
  width: 21px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-cover-video {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-nav1 {
    width: 149px;
  }
  .home-navlink04 {
    text-decoration: none;
  }
  .home-container2 {
    gap: var(--dl-space-space-unit);
    width: 180px;
    flex-wrap: wrap;
  }
  .home-login {
    height: var(--dl-size-size-xsmall);
  }
  .home-register {
    height: var(--dl-size-size-xsmall);
  }
  .home-cover {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-key-notes {
    height: var(--dl-size-size-xxlarge);
  }
  .home-statistics {
    height: var(--dl-size-size-xlarge);
  }
  .home-content {
    height: var(--dl-size-size-large);
    align-items: flex-start;
    padding-top: 0px;
    flex-direction: row;
    justify-content: flex-start;
  }
  .home-stat {
    width: auto;
    height: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    border-right-width: 1px;
    border-bottom-width: 0px;
  }
  .home-header01 {
    font-size: 40px;
  }
  .home-stat1 {
    width: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
    border-right-width: 1px;
    border-bottom-width: 0px;
  }
  .home-header02 {
    font-size: 40px;
  }
  .home-stat2 {
    width: auto;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: center;
  }
  .home-header03 {
    font-size: 40px;
  }
  .home-caption05 {
    text-align: center;
  }
  .home-selector {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-slider1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-image01 {
    width: 100%;
    height: 120px;
  }
  .home-content01 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header05 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading02 {
    font-size: 24px;
  }
  .home-caption07 {
    max-width: 600px;
  }
  .home-image02 {
    width: 100%;
    height: 120px;
  }
  .home-content02 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header06 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading03 {
    font-size: 24px;
  }
  .home-caption08 {
    max-width: 600px;
  }
  .home-image03 {
    width: 100%;
    height: 120px;
  }
  .home-content03 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header07 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading04 {
    font-size: 24px;
  }
  .home-caption09 {
    max-width: 600px;
  }
  .home-image04 {
    width: 100%;
    height: 120px;
  }
  .home-content04 {
    gap: var(--dl-space-space-unit);
    width: 100%;
    height: auto;
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
    justify-content: flex-start;
  }
  .home-header08 {
    gap: var(--dl-space-space-halfunit);
    width: 100%;
  }
  .home-heading05 {
    font-size: 24px;
  }
  .home-caption10 {
    max-width: 600px;
  }
  .home-content05 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-objectives-list {
    padding-right: 0px;
  }
  .home-objective1 {
    flex: 1;
  }
  .home-objective2 {
    flex: 1;
  }
  .home-growth {
    flex-direction: column;
  }
  .home-testimonial {
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text23 {
    max-width: 560px;
  }
  .home-experience {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .home-navlink05 {
    text-decoration: none;
  }
  .home-text32 {
    text-align: center;
  }
  .home-container3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-headers {
    flex: 0;
  }
  .home-text35 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .home-row01 {
    padding-right: 0px;
    justify-content: center;
  }
  .home-headers01 {
    flex: 0;
  }
  .home-text39 {
    color: rgba(255, 255, 255, 0);
    width: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
  .home-row03 {
    padding-right: 0px;
    justify-content: center;
  }
  .home-row04 {
    flex-direction: column;
  }
  .home-headers02 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text43 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans02 {
    width: 100%;
  }
  .home-row05 {
    padding-right: 0px;
  }
  .home-row06 {
    flex-direction: column;
  }
  .home-headers03 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text44 {
    width: 100%;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans03 {
    width: 100%;
  }
  .home-row07 {
    padding-right: 0px;
  }
  .home-row08 {
    flex-direction: column;
  }
  .home-headers04 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text45 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans04 {
    width: 100%;
  }
  .home-row09 {
    padding-right: 0px;
  }
  .home-row10 {
    flex-direction: column;
  }
  .home-headers05 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text49 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans05 {
    width: 100%;
  }
  .home-row11 {
    padding-right: 0px;
  }
  .home-row12 {
    flex-direction: column;
  }
  .home-headers06 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text53 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans06 {
    width: 100%;
  }
  .home-row13 {
    padding-right: 0px;
  }
  .home-row14 {
    flex-direction: column;
  }
  .home-headers07 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text54 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans07 {
    width: 100%;
  }
  .home-row15 {
    padding-right: 0px;
  }
  .home-row16 {
    flex-direction: column;
  }
  .home-headers08 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-text55 {
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
    border-bottom-width: 0px;
  }
  .home-plans08 {
    width: 100%;
  }
  .home-row17 {
    padding-right: 0px;
  }
  .home-row18 {
    flex-direction: column;
  }
  .home-headers09 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-plans09 {
    width: 100%;
  }
  .home-row19 {
    padding-right: 0px;
  }
  .home-faq {
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-content10 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-column {
    gap: var(--dl-space-space-unit);
    max-width: 100%;
  }
  .home-element {
    height: 124px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header16 {
    font-size: 24px;
  }
  .home-content11 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element1 {
    height: 169px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header17 {
    font-size: 24px;
  }
  .home-content12 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element2 {
    height: 163px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header18 {
    font-size: 24px;
  }
  .home-content13 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-column1 {
    gap: var(--dl-space-space-unit);
    max-width: 100%;
  }
  .home-element3 {
    height: 171px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header19 {
    font-size: 24px;
  }
  .home-content14 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-element4 {
    height: 161px;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-header20 {
    font-size: 24px;
  }
  .home-content15 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-header21 {
    font-size: 24px;
  }
  .home-content16 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-content17 {
    border-top-width: 0px;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-branding1 {
    width: 100%;
  }
  .home-image08 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .home-burger-menu {
    display: flex;
  }
  .home-nav1 {
    width: 131px;
  }
  .home-navlink03 {
    text-decoration: none;
  }
  .home-navlink04 {
    text-decoration: none;
  }
  .home-container2 {
    gap: var(--dl-space-space-unit);
    width: 175px;
    flex-wrap: wrap;
  }
  .home-login {
    height: var(--dl-size-size-xsmall);
  }
  .home-register {
    height: var(--dl-size-size-xsmall);
  }
  .home-heading {
    font-size: 60px;
  }
  .home-key-notes {
    height: 180px;
    align-items: flex-start;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-caption01 {
    font-size: 24px;
    max-width: 90%;
    line-height: 30px;
    margin-right: 0px;
  }
  .home-caption02 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-statistics {
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .home-stat {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header01 {
    font-size: 32px;
    line-height: 42px;
  }
  .home-caption03 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat1 {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header02 {
    font-size: 32px;
    line-height: 42px;
  }
  .home-caption04 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-stat2 {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header03 {
    font-size: 32px;
    line-height: 42px;
  }
  .home-caption05 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-slider {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-heading01 {
    font-size: 40px;
  }
  .home-caption06 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-slide-titles {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slides {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-slider1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-slider-track {
    align-items: stretch;
  }
  .home-heading02 {
    font-size: 18px;
  }
  .home-caption07 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-heading03 {
    font-size: 18px;
  }
  .home-caption08 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-heading04 {
    font-size: 18px;
  }
  .home-caption09 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-heading05 {
    font-size: 18px;
  }
  .home-caption10 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-previous {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon03 {
    width: 14px;
    height: 14px;
  }
  .home-next {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon05 {
    width: 14px;
    height: 14px;
  }
  .home-get-started {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-heading06 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-caption11 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image05 {
    width: 300px;
  }
  .home-objectives {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content05 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-objectives-list {
    width: 100%;
    flex-direction: column;
  }
  .home-growth {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-image06 {
    width: 100%;
  }
  .home-content06 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header10 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading07 {
    font-size: 40px;
  }
  .home-caption12 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-caption13 {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text22 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-icon07 {
    width: 40px;
    height: 40px;
  }
  .home-name {
    font-size: 12px;
    line-height: 18px;
  }
  .home-from {
    width: auto;
    height: 100%;
  }
  .home-experience {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content08 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header12 {
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-header-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading08 {
    font-size: 40px;
  }
  .home-caption14 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text30 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-icon16 {
    width: 40px;
    height: 40px;
  }
  .home-name1 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-from1 {
    width: auto;
    height: 100%;
  }
  .home-image07 {
    width: 100%;
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-heading09 {
    font-size: 40px;
  }
  .home-text32 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container3 {
    grid-template-columns: 1fr 1fr;
  }
  .home-pricing {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header-container3 {
    gap: var(--dl-space-space-unit);
  }
  .home-header14 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading10 {
    font-size: 40px;
  }
  .home-caption15 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text36 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text37 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text38 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text40 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text41 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text42 {
    font-size: 12px;
    line-height: 14px;
  }
  .home-text43 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text44 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text45 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text46 {
    line-height: 21px;
  }
  .home-text47 {
    line-height: 21px;
  }
  .home-text48 {
    line-height: 21px;
  }
  .home-text49 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text50 {
    line-height: 21px;
  }
  .home-text51 {
    line-height: 21px;
  }
  .home-text52 {
    line-height: 21px;
  }
  .home-text53 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text54 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-text55 {
    font-size: 12px;
    line-height: 18px;
  }
  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-header15 {
    gap: var(--dl-space-space-unit);
    align-items: center;
  }
  .home-heading11 {
    font-size: 36px;
  }
  .home-column {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element {
    gap: var(--dl-space-space-halfunit);
    height: 89px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
  }
  .home-header16 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element1 {
    gap: var(--dl-space-space-halfunit);
    height: 115px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
  }
  .home-header17 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element2 {
    gap: var(--dl-space-space-halfunit);
    height: 120px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-halfunit);
    border-right-width: 0px;
  }
  .home-header18 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-column1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-element3 {
    gap: var(--dl-space-space-halfunit);
    height: 114px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
  }
  .home-header19 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element4 {
    gap: var(--dl-space-space-halfunit);
    height: 132px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
  }
  .home-header20 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-element5 {
    gap: var(--dl-space-space-halfunit);
    height: 151px;
    padding-top: 0px;
    padding-right: 00px;
    padding-bottom: var(--dl-space-space-unit);
    border-right-width: 0px;
  }
  .home-header21 {
    font-size: 16px;
    line-height: 20px;
  }
  .home-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content17 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image08 {
    width: auto;
  }
  .home-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .home-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .home-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-icon {
    width: 30px;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-nav {
    align-self: flex-end;
    flex-direction: column;
  }
  .home-nav1 {
    width: 150px;
  }
  .home-container2 {
    gap: var(--dl-space-space-unit);
    width: 182px;
    flex-wrap: wrap;
    align-self: flex-end;
    margin-top: 0px;
    justify-content: flex-start;
  }
  .home-login {
    height: var(--dl-size-size-xsmall);
  }
  .home-register {
    height: var(--dl-size-size-xsmall);
  }
  .home-cover {
    gap: var(--dl-space-space-threeunits);
  }
  .home-heading {
    font-size: 36px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-button {
    text-align: center;
  }
  .home-key-notes {
    height: var(--dl-size-size-xxlarge);
  }
  .home-caption01 {
    line-height: 30px;
  }
  .home-content {
    height: var(--dl-size-size-xlarge);
  }
  .home-stat {
    border-right-width: 0px;
  }
  .home-header01 {
    font-size: 24px;
  }
  .home-stat1 {
    border-right-width: 0px;
  }
  .home-header02 {
    font-size: 24px;
  }
  .home-header03 {
    font-size: 24px;
  }
  .home-heading01 {
    font-size: 36px;
    line-height: 42px;
  }
  .home-slide-titles {
    display: none;
  }
  .home-image05 {
    height: 400px;
  }
  .home-text13 {
    font-size: 18px;
  }
  .home-text15 {
    font-size: 18px;
  }
  .home-text17 {
    font-size: 18px;
  }
  .home-heading07 {
    font-size: 36px;
  }
  .home-heading08 {
    color: rgb(255, 255, 255);
  }
  .home-text25 {
    font-style: italic;
    font-family: Playfair Display;
    font-weight: 500;
  }
  .home-gallery {
    padding: var(--dl-space-space-unit);
  }
  .home-heading09 {
    color: rgb(255, 255, 255);
  }
  .home-container3 {
    grid-template-columns: 1fr;
  }
  .home-pricing {
    gap: var(--dl-space-space-halfunit);
  }
  .home-header14 {
    gap: 0px;
    flex-direction: column;
  }
  .home-heading10 {
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .home-text40 {
    color: rgb(124, 124, 128);
  }
  .home-text41 {
    color: rgb(124, 124, 128);
  }
  .home-link11 {
    text-align: center;
  }
  .home-faq {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-header15 {
    gap: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-column {
    gap: var(--dl-space-space-twounits);
  }
  .home-element {
    height: 150px;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-element1 {
    height: 175px;
  }
  .home-element2 {
    height: 175px;
  }
  .home-column1 {
    gap: var(--dl-space-space-twounits);
  }
  .home-element3 {
    height: 195px;
    margin-bottom: 11px;
  }
  .home-element4 {
    height: 205px;
  }
  .home-element5 {
    height: 200px;
  }
  .home-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-link14 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .home-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .home-button5 {
    justify-content: center;
  }
}
