.download-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #000000;
}
.download-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.download-navlink {
  display: contents;
}
.download-branding {
  width: auto;
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.download-burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-hamburger {
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fourunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
  background-color: 3d9363;
}
.download-hamburger:hover {
  opacity: .5;
}
.download-icon {
  width: 60px;
  object-fit: cover;
}
.download-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-black);
}
.download-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.download-navlink01 {
  display: contents;
}
.download-image {
  height: 2rem;
  text-decoration: none;
}
.download-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.download-icon1 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.download-nav1 {
  flex: 0 0 auto;
  width: 139px;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.download-navlink02 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.download-link {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.download-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.download-navlink03 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.download-navlink04 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.download-container2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  width: 183px;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.download-login {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.download-register {
  width: var(--dl-size-size-large);
  height: var(--dl-size-size-xsmall);
  align-self: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.download-get-started {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.download-header-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  z-index: 100;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.download-header {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.download-heading {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.download-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  line-height: 36px;
}
.download-button {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}
.download-button1 {
  text-decoration: none;
}
.download-image1 {
  top: 0px;
  right: 0px;
  width: 720px;
  bottom: 0px;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
.download-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.download-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  border-color: #313133;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  border-top-width: 1px;
}
.download-main {
  gap: 240px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.download-branding1 {
  gap: 0;
  width: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-navlink05 {
  display: contents;
}
.download-image2 {
  width: auto;
  object-fit: cover;
  text-decoration: none;
}
.download-text1 {
  color: #ffffff;
  line-height: 24px;
}
.download-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.download-column {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 173px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-header1 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.download-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-navlink06 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink06:hover {
  opacity: .5;
}
.download-navlink07 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink07:hover {
  opacity: .5;
}
.download-link2 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-link2:hover {
  opacity: .5;
}
.download-navlink08 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink08:hover {
  opacity: .5;
}
.download-text2:hover {
  opacity: .5;
}
.download-column1 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 144px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-header2 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.download-list1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-link3 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-link3:hover {
  opacity: .5;
}
.download-link4 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-link4:hover {
  opacity: .5;
}
.download-navlink09 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink09:hover {
  opacity: .5;
}
.download-navlink10 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink10:hover {
  opacity: .5;
}
.download-navlink11 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink11:hover {
  opacity: .5;
}
.download-column2 {
  gap: var(--dl-space-space-threeunits);
  color: white;
  width: 147px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-header3 {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
}
.download-list2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.download-navlink12 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink12:hover {
  opacity: .5;
}
.download-link5 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-link5:hover {
  opacity: .5;
}
.download-navlink13 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink13:hover {
  opacity: .5;
}
.download-navlink14 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-navlink14:hover {
  opacity: .5;
}
.download-link6 {
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  line-height: 32px;
  text-decoration: none;
}
.download-link6:hover {
  opacity: .5;
}
.download-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.download-text4 {
  color: rgb(124, 124, 128);
  font-size: 14px;
  line-height: 21px;
}
@media(max-width: 991px) {
  .download-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .download-nav1 {
    width: 149px;
  }
  .download-navlink04 {
    text-decoration: none;
  }
  .download-container2 {
    gap: var(--dl-space-space-unit);
    width: 180px;
    flex-wrap: wrap;
  }
  .download-login {
    height: var(--dl-size-size-xsmall);
  }
  .download-register {
    height: var(--dl-size-size-xsmall);
  }
  .download-content {
    border-top-width: 0px;
  }
  .download-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .download-branding1 {
    width: 100%;
  }
  .download-image2 {
    width: auto;
  }
}
@media(max-width: 767px) {
  .download-burger-menu {
    display: flex;
  }
  .download-nav1 {
    width: 131px;
  }
  .download-navlink03 {
    text-decoration: none;
  }
  .download-navlink04 {
    text-decoration: none;
  }
  .download-container2 {
    gap: var(--dl-space-space-unit);
    width: 175px;
    flex-wrap: wrap;
  }
  .download-login {
    height: var(--dl-size-size-xsmall);
  }
  .download-register {
    height: var(--dl-size-size-xsmall);
  }
  .download-get-started {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .download-heading {
    font-size: 36px;
    line-height: 42px;
  }
  .download-image1 {
    width: 300px;
  }
  .download-footer {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .download-content {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .download-image2 {
    width: auto;
  }
  .download-links {
    gap: var(--dl-space-space-fiveunits);
    flex-direction: row;
  }
}
@media(max-width: 479px) {
  .download-branding {
    padding-top: var(--dl-space-space-unit);
  }
  .download-hamburger {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .download-icon {
    width: 30px;
  }
  .download-mobile-menu {
    padding: 16px;
  }
  .download-nav {
    align-self: flex-end;
    flex-direction: column;
  }
  .download-nav1 {
    width: 150px;
  }
  .download-container2 {
    gap: var(--dl-space-space-unit);
    width: 182px;
    flex-wrap: wrap;
    align-self: flex-end;
    margin-top: 0px;
    justify-content: flex-start;
  }
  .download-login {
    height: var(--dl-size-size-xsmall);
  }
  .download-register {
    height: var(--dl-size-size-xsmall);
  }
  .download-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .download-image1 {
    height: 400px;
  }
  .download-links {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .download-link3 {
    font-size: 16px;
    line-height: 32px;
    text-decoration: none;
  }
  .download-bottom {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
}
